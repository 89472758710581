.header-container {
    height: 50px;
    width: 99.2vw;
    padding-left: 60px;
    padding-right: 60px;
    background-color: #1f633e;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .app-name-and-nav-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 86%;
  }
  
  .nav-btn-container {
    display: flex;
    flex-direction: row;
    width: 30%;
    max-width: 250px;
    min-width: 250px;
    padding-left: 50px;
    justify-content: space-between;
  }
  
  .nav-btn {
    cursor: pointer;
  }
  
  .app-name {
    font-size: 24px;
  }
  
  .cart-and-user-name-container {
    width: 14%;
    max-width: 200px;
    min-width: 200px;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    align-items: center;
  }
  
  .cart-icon-container {
    cursor: pointer;
  }
  
  .user-name {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  