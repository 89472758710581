/* itemListStyle.css */

.Approved {
    background-color: #4caf50; /* Green */
  }
  
  .PriceUpdated,
  .QuantityUpdated,
  .QuantityPriceUpdated,
  .PriceNotSame,
  .QuantityNotSame,
  .Other {
    background-color: #4caf50; /* Green */
  }

  .ItemStatusMissingUrgent{
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    color: white;
    background-color: #dc3545; 
  }
  
  .ItemStatusMissing {
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    color: white;
    background-color: #ffc107; /* Yellow */
  }
  
  .MissingUrgent {
    background-color: #dc3545; /* Red */
  }
  
  .RightIcon {
    font-size: 22px;
    
  }

  .RightIconApproved {
    font-size: 22px;
    color: green;
    
    
  }
  
  .CancelIcon {
    font-size: 22px;
  }
  
  .EditText {
    cursor: pointer;
    font-size: 18px;
  }
  
  .ItemQty {
    color: black;
    font-weight: bold;
  }
  
  .ListContainer {
    margin-top: 10px;
  }
  
  .TableContainer {
    width: 90vw;
    min-width: 1100px;
    max-width: 1800px;
    margin: 10px auto;
    border: 1px solid #e9e8e8;
    background-color: #fefffe;
    border-radius: 10px;
    padding: 20px 60px;
  }
  
  .Table {
    width: 100%;
  }
  
  .Table tr {
    padding: 10px;
  }
  
  .Table th {
    color: #9d9c9d;
  }
  
  .Table td {
    color: #6e6e6e;
    border-bottom: 1px solid #ddd;
    text-align: left;
    padding: 20px 10px;
  }
  
  .Table td:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .Table td:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .InputBtnContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .InputContainer {
    width: 200px;
    border: 1px solid gray;
    border-radius: 25px;
    position: relative;
    height: 100%;
    height: 40px;
    padding-left: 5px;
  }
  
  .SearchInput {
    border: none;
    padding: 10px 30px;
    outline: none;
    background-color: transparent;
  }
  
  .SearchIconContainer {
    position: absolute;
    background: none;
    border: none;
    left: 5;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .Search {
    font-size: 18px;
  }
  
  .BtnsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .NewItemBtn {
    height: 30px;
    border: 1px solid green;
    border-radius: 15px;
    color: #1f633e;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;
  }
  
  .ItemStatus {
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    color: gray;
  }

  .ItemStatusApproved {
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    color: white;
    background-color: green;
  }
  
  .ItemImage {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  
  .IconContainer {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  