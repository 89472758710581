.ListContainer {
    margin-top: 10px;
  }
  
  .TableContainer {
    width: 90vw;
    min-width: 1100px;
    max-width: 1800px;
    margin: 10px auto;
    border: 1px solid #e9e8e8;
    background-color: #fefffe;
    border-radius: 10px;
    padding: 20px 60px;
  }
  
  .InputBtnContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .InputContainer {
    width: 200px;
    border: 1px solid gray;
    border-radius: 25px;
    position: relative;
    height: 40px;
    padding-left: 5px;
  }
  
  .SearchInput {
    border: none;
    padding: 10px 30px;
    outline: none;
    background-color: transparent;
  }
  
  .SearchIconContainer {
    position: absolute;
    background: none;
    border: none;
    left: 5;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .Search {
    font-size: 18px;
  }
  
  .BtnsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .NewItemBtn {
    height: 30px;
    border: 1px solid green;
    border-radius: 15px;
    color: #1f633e;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;
  }
  