.order-details-container {
    width: 90vw;
    margin: 30px auto;
    border: 1px solid #e9e8e8;
    background-color: #fefffe;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
  }
  
  .item {
    flex: 1;
    padding-left: 30px;
    height: 80px;
  }
  
  .item-header {
    margin-bottom: 15px;
    color: #8d8c8c;
    font-size: 12px;
  }
  
  .item-details {
    font-size: 18px;
    font-weight: bold;
    width: 90%;
  }
  
  .separator {
    height: 90px;
    border: 1px solid #f3f3f2;
  }
  
  .category-image {
    height: 50px;
    width: 200px;
  }
  