/* missingProduct.css */

.missing-product-container {
    border: 1px solid gray;
    width: 500px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 20px;
    position: relative;
  }
  
  .button {
    margin-right: 30px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    cursor: pointer;
  }
  
  .close-icon {
    position: absolute;
    right: 20px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    height: 30px;
    width: 30px;
  }
  
  .right-btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  